export const cellColors = [
  "#2980b9", // belize blue
  "#c0392b", // pomegranate
  "#f39c12", // orange
  "#8e44ad", // wisteria
  "#27ae60", // nephritis
  "#f1c40f", // sunflower
  "#ffffff", // white
  "#000000", //black
];
